import ApiHelper from "../ApiHelper";

export async function GetAllSubjects(ids: string[]) {
  const promises = ids.map((id) => {
    return ApiHelper.get(`/api/v1/board/getAllSubjects/${id}`).then(
      (response: any) => response
    );
  });
  try {
    const results = await Promise.all(promises);
    return results;
  } catch (error) {
    return error;
  }
}

export async function GetAllSubjectAndChapterWiseSimulations(subjectIds: string[]) {
  try {
    return new Promise((resolve, reject) => {
      ApiHelper.post(
        `/api/v1/simulation/getSimulationsCatalogueData`,
        {subjectIds:subjectIds}
      )
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  } catch (error) {
    return error;
  }
}
