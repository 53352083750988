import { SubjectWiseSimulationDataObject } from "../TopicDetails/Modals/ShowSimulationsCatalogueModal";

export function SimualtionsCatalogueUI(
  dataList: SubjectWiseSimulationDataObject[]
) {
  return (
<html lang="en">
<head>
  <style>{`
    body {
      font-family: 'Arial', sans-serif;
      margin: 0;
      padding: 0;
      border-radius: 12px;
      border: 2px solid #ddd;
    }

    .page {
      page-break-after: always;
      padding: 2rem;
    }

    .cover-page {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 80vh;
      text-align: center;
    }

    .cover-title {
      font-size: 3.5rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 1rem;
      margin-top: 2rem; /* Moves the heading slightly more up */
    }

    .cover-subtitle {
      font-size: 1.5rem;
      font-weight: 600;
      color: #555;
      margin-bottom: 3rem;
    }

    .cover-page img.logo {
      width: 120px;
      height: auto;
      margin-bottom: 2rem;
    }

    .cover-page .cover-image {
      width: 100%;
      marginTop:4rem;
      max-height: 60vh;
      object-fit: cover;
    }

    .subject-page {
      padding: 2rem;
      background-color: white;
      margin: 1rem auto;
      max-width: 90%;
    }

    .subject-header {
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      color: #000000;
      margin-bottom: 2rem;
    }

    ol {
      list-style: decimal;
      margin: 0;
      padding-left: 1.5rem;
    }

    .list-item {
      margin-bottom: 1.5rem;
    }

    .list-item a {
      font-weight: bold;
      text-decoration: underline;
      color: #0d47a1;
      cursor: pointer;
      font-size: 1.2rem;
    }

    .list-item p {
      margin: 0.5rem 0 0;
      color: #555;
      font-size: 1rem;
    }
  `}</style>
</head>
<body>
  <div className="page cover-page">
    <img src="https://vignam-content-images.s3.ap-south-1.amazonaws.com/2025-01-28T11-17-26-018Z.png" alt="Vignam Logo" className="logo" />
    <div>
      <h1 className="cover-title">Vignam Simulations</h1>
      <p className="cover-subtitle">Explore a world of Interactive Teaching</p>
    </div>
    <img src="https://vignam-content-images.s3.ap-south-1.amazonaws.com/2025-01-28T11-18-53-041Z.webp" alt="Simulations Banner" className="cover-image" />
  </div>

  {dataList.map((subject, index) => (
    <div key={index} className="page subject-page">
      <div className="subject-header">
        <h2>{subject.subjectName}</h2>
      </div>
      <ol>
        {subject.simulations.map((simulation: any, simIndex: number) => (
          <li key={simIndex} className="list-item">
            <a
              href={simulation.thumbnailImageUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {simulation.name}
            </a>
            <p>
              {(simulation.description ?? "").length > 0
                ? simulation.description
                : simulation.simulationDescription}
            </p>
          </li>
        ))}
      </ol>
    </div>
  ))}
</body>
</html>
  );
}
