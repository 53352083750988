
import axios from "axios";

import { GetValueFromLocalStorage, LocalStorageKey } from "../utilities/LocalstorageUtility";



export async function htmlToPdf(params: {
    html: string;
    showBorder: boolean;
  }) {
    const response = await axios.post("/api/v1/convertPDf/    ", params, {
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    });
    const status = response.status;
    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }
    return [];
  }
  