import {
  Button,
  Center,
  MultiSelect,
  Radio,
  Stack,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import {
  GetAllSubjects,
  GetAllSubjectAndChapterWiseSimulations,
} from "../../../features/subjects/getAllSubjectsSlice";
import ReactDOMServer from "react-dom/server";
import { htmlToPdf } from "../../../features/generatePdfSlice";
import { SimualtionsCatalogueUI } from "../../Simulations/SimulationCatalogueUI";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export interface SubjectWiseSimulationDataObject {
  subjectId: string;
  subjectName: string;
  className: string;
  simulations: any[];
}

export function ShowSimualtionsCatalogueModal() {
  const [subjectList, setSubjectList] = useState<
    { value: string; label: string; className: string }[]
  >([]);

  const [selectedSubjectValue, setSelectedSubjects] = useState<string[]>([]);

  const [
    subjectWiseSimulationDataForCatalogue,
    setSubjectWiseSimulationDataForCatalogue,
  ] = useState<Map<string, SubjectWiseSimulationDataObject>>();

  const [
    subjectWiseSimulationDataForExcel,
    setSubjectWiseSimulationDataForExcel,
  ] = useState<Map<string, SubjectWiseSimulationDataObject>>();

  const [instituteId, setInstituteId] = useState("");

  useEffect(() => {
    if (subjectWiseSimulationDataForCatalogue != null) {
      const subjectWiseSimulationsArray: SubjectWiseSimulationDataObject[] = [];
      Array.from(subjectWiseSimulationDataForCatalogue.values()).forEach(
        (value: SubjectWiseSimulationDataObject) => {
          subjectWiseSimulationsArray.push(value);
        }
      );
      htmlToPdf({
        html: ReactDOMServer.renderToString(
          SimualtionsCatalogueUI(subjectWiseSimulationsArray)
        ),
        showBorder: false,
      }).then((data: any) => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([data], { type: "application/pdf" })
        );
        link.download = `simulation-catalogue.pdf`;
        link.click();
        link.remove();
      });
    }
  }, [subjectWiseSimulationDataForCatalogue]);

  useEffect(() => {
    if (subjectWiseSimulationDataForExcel != null) {
      const subjectWiseSimulationsArray: SubjectWiseSimulationDataObject[] = [];
      Array.from(subjectWiseSimulationDataForExcel.values()).forEach(
        (value: SubjectWiseSimulationDataObject) => {
          subjectWiseSimulationsArray.push(value);
        }
      );
      downloadSimulationExcel(subjectWiseSimulationsArray);
    }
  }, [subjectWiseSimulationDataForExcel]);

  useEffect(() => {
    GetAllSubjects(["BOD-9dabe90f-5c8f-43eb-8c48-c527b1555793"])
      .then((response: any) => {
        const data = response[0];
        const extractedSubjects: {
          value: string;
          label: string;
          className: string;
        }[] = data?.classes?.reduce((acc: any[], cls: any) => {
          const subjects = cls.subjects.map((subject: any) => ({
            value: subject._id,
            label: `${cls.name} ${subject.name}`,
            className: cls.name,
          }));
          return acc.concat(subjects);
        }, []);
        setSubjectList(extractedSubjects);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const [url, setUrl] = useState<string>("https://staging.vignamlabs.com");
  function downloadSimulationExcel(
    dataList: SubjectWiseSimulationDataObject[]
  ) {
    const workbook = new ExcelJS.Workbook();

    // Loop through each subject to create a separate sheet
    dataList.forEach((dataObj) => {
      const worksheet = workbook.addWorksheet(dataObj.subjectName); // Sheet named after subjectName
      // Add header row

      // Auto-adjust column width
      worksheet.columns = [
        { header: "Chapter Name", key: "name", width: 30 },
        { header: "Simulation Name", key: "name", width: 30 },
        { header: "Simulation Name", key: "name", width: 100 },
      ];

      // Add each simulation name under the subject
      dataObj.simulations.forEach((simulation) => {
        worksheet.addRow([
          simulation.assignedChapters.find((singleChapter: any) => {
            return singleChapter.subjectId == dataObj.subjectId;
          })?.name ?? "",
          simulation.name,
          {
            text: `${url}/openSimulation/${simulation._id}${
              instituteId && instituteId.length > 0
                ? "?def_token=" + instituteId
                : ""
            }`,
            hyperlink: `${url}/openSimulation/${simulation._id}${
              instituteId && instituteId.length > 0
                ? "?def_token=" + instituteId
                : ""
            }`,
          },
        ]);
      });
    });

    // Generate Excel file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "Simulation_Catalogue.xlsx");
    });
  }
  const [prodOrStageValue, setProdOrStageValue] = useState("stage");

  useEffect(() => {
    if (prodOrStageValue.toLowerCase() == "prod") {
      setUrl("https://app.vignamlabs.com");
    } else {
      setUrl("https://staging.vignamlabs.com");
    }
  }, [prodOrStageValue]);

  return (
    <Stack spacing={8}>
      <Radio.Group
        name="Prod OR Staging"
        label="Select Prod or Staging"
        description="Select Prod Or Staging"
        withAsterisk
        onChange={setProdOrStageValue}
        value={prodOrStageValue}
      >
        <Radio value="stage" label="stage" />
        <Radio value="prod" label="prod" />
      </Radio.Group>

      <TextInput
        value={instituteId}
        onChange={(event) => setInstituteId(event.currentTarget.value)}
        placeholder="InstitueId"
        mt={24}
      />

      <MultiSelect
        data={subjectList}
        onChange={setSelectedSubjects}
      ></MultiSelect>

      <Button
        onClick={() => {
          if (selectedSubjectValue.length > 0) {
            GetAllSubjectAndChapterWiseSimulations(selectedSubjectValue)
              .then((data: any) => {
                const subjectWiseSimulationDataInside: Map<
                  string,
                  SubjectWiseSimulationDataObject
                > = new Map();

                // Iterate through the simulations array and fill the map
                data.forEach((simulation: any) => {
                  simulation.assignedSubjects.forEach((subjectId: string) => {
                    if (
                      selectedSubjectValue.findIndex(
                        (singleSubjectId: string) => {
                          return singleSubjectId == subjectId;
                        }
                      ) != -1
                    ) {
                      if (!subjectWiseSimulationDataInside.has(subjectId)) {
                        // If the subjectId is not already in the map, initialize it with an empty array
                        const desiredSubject = subjectList.find(
                          (singleSubject) => {
                            return singleSubject.value == subjectId;
                          }
                        );
                        subjectWiseSimulationDataInside.set(subjectId, {
                          subjectId: subjectId,
                          subjectName: desiredSubject!!.label,
                          className: desiredSubject!!.className,
                          simulations: [],
                        });
                      }
                      // Get the existing simulations for this subjectId
                      const existingSimulations =
                        subjectWiseSimulationDataInside.get(subjectId)!!
                          .simulations;

                      // Check if the simulation is already added
                      if (
                        existingSimulations &&
                        !existingSimulations.some(
                          (sim: any) => sim._id === simulation._id
                        )
                      ) {
                        existingSimulations.push(simulation);
                      }
                    }
                  });
                });
                subjectWiseSimulationDataInside.forEach((dataObj) => {
                  dataObj.simulations = dataObj.simulations.sort(
                    (a: any, b: any) => {
                      const chapterA =
                        a.assignedChapters.find((singleChapter: any) => {
                          return singleChapter.subjectId === dataObj.subjectId;
                        })?.name ?? "";

                      const chapterB =
                        b.assignedChapters.find((singleChapter: any) => {
                          return singleChapter.subjectId === dataObj.subjectId;
                        })?.name ?? "";

                      return chapterA.localeCompare(chapterB); // Correct string sorting
                    }
                  );
                });
                setSubjectWiseSimulationDataForExcel(
                  subjectWiseSimulationDataInside
                );
              })
              .catch((err) => {});
          } else {
            alert("No ids");
          }
        }}
      >
        Generate Excel
      </Button>

      <Button
        onClick={() => {
          if (selectedSubjectValue.length > 0) {
            GetAllSubjectAndChapterWiseSimulations(selectedSubjectValue)
              .then((data: any) => {
                const subjectWiseSimulationDataInside: Map<
                  string,
                  SubjectWiseSimulationDataObject
                > = new Map();

                // Iterate through the simulations array and fill the map
                data.forEach((simulation: any) => {
                  simulation.assignedSubjects.forEach((subjectId: string) => {
                    if (
                      selectedSubjectValue.findIndex(
                        (singleSubjectId: string) => {
                          return singleSubjectId == subjectId;
                        }
                      ) != -1
                    ) {
                      if (!subjectWiseSimulationDataInside.has(subjectId)) {
                        // If the subjectId is not already in the map, initialize it with an empty array
                        const desiredSubject = subjectList.find(
                          (singleSubject) => {
                            return singleSubject.value == subjectId;
                          }
                        );
                        subjectWiseSimulationDataInside.set(subjectId, {
                          subjectId: subjectId,
                          subjectName: desiredSubject!!.label,
                          className: desiredSubject!!.className,
                          simulations: [],
                        });
                      }
                      // Get the existing simulations for this subjectId
                      const existingSimulations =
                        subjectWiseSimulationDataInside.get(subjectId)!!
                          .simulations;

                      // Check if the simulation is already added
                      if (
                        existingSimulations &&
                        !existingSimulations.some(
                          (sim: any) => sim._id === simulation._id
                        )
                      ) {
                        existingSimulations.push(simulation);
                      }
                    }
                  });
                });

                subjectWiseSimulationDataInside.forEach((dataObj) => {
                  dataObj.simulations = dataObj.simulations.sort(
                    (a: any, b: any) => {
                      const chapterA =
                        a.assignedChapters.find((singleChapter: any) => {
                          return singleChapter.subjectId === dataObj.subjectId;
                        })?.name ?? "";

                      const chapterB =
                        b.assignedChapters.find((singleChapter: any) => {
                          return singleChapter.subjectId === dataObj.subjectId;
                        })?.name ?? "";

                      return chapterA.localeCompare(chapterB); // Correct string sorting
                    }
                  );
                });

                setSubjectWiseSimulationDataForCatalogue(
                  subjectWiseSimulationDataInside
                );
              })
              .catch((err) => {});
          } else {
            alert("No ids");
          }
        }}
      >
        Generate PDF
      </Button>
    </Stack>
  );
}
